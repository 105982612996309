import { FormControl, Grid, Hidden, TextField, FormControlLabel, Checkbox, Button, Select, Tooltip, InputLabel, MenuItem, FormHelperText, Icon, IconButton } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { factory } from '../../../../helpers/factory';
import utils from '../../../../helpers/validations';
import Spinner from '../../../shared/spinner';
import InfoIcon from '@material-ui/icons/Info';
import QrCodeIcon from '../../../../resources/svgs/QrCodeSVG';
import GoogleMaps from "../../../vehicles/form/mapsInput";
import { googleMapsService } from "../../../../services/googleMapsService";
import { history } from '../../../../helpers/history';
import { withTheme } from '@material-ui/core/styles';
import { allowAccessRole } from '../../../../helpers/roles';
import { MODULES, OPERATIONS_ALLOWED } from '../../../roles/constants';
import { getAddress, getBirthDate, getCountryCode, getDocumentType, getEmail, getHasWheelChair, getId, getIdentityDocumentCode, getIsDisabled, getIsPMR, getName, getPhoneNumber, getPhoneNumberWithOutCode, getPrefix, getSurname1, getSurname2, getUser } from './tools';
import QRCode from 'qrcode';
import CryptoJS from 'crypto-js';
import PhoneInput from 'react-phone-input-2'
import es from 'react-phone-input-2/lang/es.json'
import 'react-phone-input-2/lib/material.css'
import './styles.css'
import jsPDF from 'jspdf';
import path from 'path';

const styles = makeStyles(theme => ({
    ' & .MuiFormControl-root': {
        width: '100%',
    },
}))

const IconWithTooltip = props => (
    <Tooltip title={props.text}>
        <InfoIcon />
    </Tooltip>
)

class PersonalData extends React.Component {
    constructor(props) {
        super()
        const user = getUser(props.user, props.customerReducer)
        const detail = props.detail
        const isRelatedCustomer = props.isModal
        this.state = {
            id: getId(user),
            name: getName(user),
            surname1: getSurname1(user),
            surname2: getSurname2(user),
            email: getEmail(user),
            emailEmpty: user.email == '',
            documentType: getDocumentType(user),
            identityDocumentCode: getIdentityDocumentCode(user),
            phoneNumber: getPhoneNumber(user, isRelatedCustomer),
            phoneNumberWithoutCode: getPhoneNumberWithOutCode(user),
            prefix: getPrefix(user),
            countryCode: getCountryCode(user),
            birthDate: getBirthDate(user),
            isPMR: getIsPMR(user),
            isDisabled: getIsDisabled(user),
            address: getAddress(user),
            hasWheelChair: getHasWheelChair(user),
            errors: {
                address: { result: true, message: '' },
                name: { result: false, message: '' },
                surname1: { result: false, message: '' },
                surname2: { result: true, message: '' },
                birthDate: { result: detail ? true : false, message: '' },
                email: { result: detail ? true : false, message: '' },
                identityDocumentCode: {
                    result: detail ? true : false,
                    message: '',
                },
                phoneNumber: { result: detail ? true : false, message: '' },
            },
        }
        this.handleChange = this.handleChange.bind(this)
        this.onClick = this.onClick.bind(this)
        this.handleChangePhone = this.handleChangePhone.bind(this)
        this.downloadQrCode = this.downloadQrCode.bind(this)
    }

    handleChange(event) {
        let { name, value } = event.target
        let errors = this.state.errors
        errors[name].message = ''
        this.setState({ [name]: value })
    }

    handleChangePhone(value, data) {
        this.setState({
            phoneNumber: value,
            phoneNumberWithoutCode: value.length > 0 ? value.slice(data.dialCode.length) : value,
            countryCode: data.countryCode,
            prefix: '+' + data.dialCode
        })
    }

    isFormValid() {
        let valid = true
        let properties = Object.getOwnPropertyNames(this.state.errors)
        properties.forEach(element => {
            if (!this.state.errors[element].result)
                valid = false
        });
        if (this.props.errorsFavouriteAddresses) {
            this.props.errorsFavouriteAddresses.forEach(element => {
                properties = Object.getOwnPropertyNames(element)
                properties.forEach(key => {
                    if (!element[key].result)
                        valid = false
                });
            });
        }
        return valid
    }

    onClickBack() {
        history.goBack()
    }

    onSelectChange = event => {
        this.setState({ documentType: event.target.value });
    }

    checkCantEdit() {
        const theme = this.props.theme;
        return !theme.isAmtu && (this.props.edit || this.props.detail)
    }

    validateForm() {
        let errors = this.state.errors
        errors.name = utils.validateName(this.state.name)
        errors.surname1 = utils.validateLastName(this.state.surname1)
        errors.surname2 =
            this.state.surname2 != ''
                ? utils.validateLastName(this.state.surname2)
                : { result: true, message: '' }
        let detail = this.props.detail
        if (!detail) {
            if (this.state.email != '' && !this.props.isModal) {
                errors.email = utils.validateEmail(this.state.email)
            } else {
                errors.email = { result: true, message: '' }
            }
            errors.birthDate = utils.required(this.state.birthDate)
            if (errors.birthDate.result && !this.props.isModal) {
                errors.birthDate = utils.calculateAge(this.state.birthDate, 18)
            }
            errors.phoneNumber = utils.required(this.state.phoneNumber)
            if (errors.phoneNumber.result) {
                if (!this.props.isModal)
                    errors.phoneNumber = utils.validateInternationalPhone(
                        this.state.phoneNumberWithoutCode, this.state.countryCode, this.state.prefix.replace('+', '')
                    )
                else
                    errors.phoneNumber = utils.validatePhone(this.state.phoneNumberWithoutCode)
            }
            errors.identityDocumentCode = utils.validateID(this.state.identityDocumentCode, this.state.documentType)

            if (this.props.validateFavoriteAddresses)
                this.props.validateFavoriteAddresses()
        }

        this.setState({ errors })
    }

    onClick() {
        this.validateForm()
        if (this.isFormValid()) {
            const customer = factory.createCustomer(this.state.id, this.state.name, this.state.surname1, this.state.surname2, this.state.documentType, this.state.identityDocumentCode, this.state.email,
                this.state.phoneNumberWithoutCode, this.state.isPMR, this.state.isDisabled, this.state.hasWheelChair, this.state.birthDate, this.state.address, this.props.favouriteAddresses, this.state.prefix, this.state.countryCode)
            if (this.props.isModal) {
                if (!customer.phoneNumber.includes(this.state.prefix))
                    customer.phoneNumber = this.state.prefix + customer.phoneNumber
                if (this.props.customerReducer.add) {
                    this.props.addRelatedCustomer(customer)
                } else {
                    customer.tableData = {
                        id: this.props.customerReducer.userEdit.tableData.id,
                    }
                    this.props.editRelatedCustomer(customer)
                }
                this.props.closeModal()
            } else {
                const customer = factory.createCustomer(this.state.id, this.state.name, this.state.surname1, this.state.surname2, this.state.documentType, this.state.identityDocumentCode, this.state.email,
                    this.state.phoneNumberWithoutCode, this.state.isPMR, this.state.isDisabled, this.state.hasWheelChair, this.state.birthDate, this.state.address, this.props.favouriteAddresses, this.state.prefix, this.state.countryCode)
                customer.relatedCustomers = this.props.customerReducer.relatedCustomers
                this.props.sendCustomer(customer)
            }
        }
    }

    onChangeAddress = newValue => {
        if (newValue) {
            googleMapsService
                .getLocation(newValue.description)
                .then(location => {
                    this.setState({
                        address: {
                            address: newValue.description,
                            lat: location.lat,
                            lon: location.lng,
                        },
                    })
                })
        } else {
            this.setState({
                address: { address: '', location: null },
            })
        }
    }

    downloadQrCode() {
        const { id, name, surname1 } = this.state
        const hashedId = CryptoJS.AES.encrypt(id, "TemporalDevKey").toString();
        //As we are putting our logo in the middle of the code, it's better to set the error correction level to high to ensure that the code is read correctly every time 
        QRCode.toDataURL(hashedId, { errorCorrectionLevel: 'H' }, function (err, url) {
            if (err === null) {
                let img = new Image()
                img.src = '\\content\\img\\flexitransport-logo.png'
                let pdf = new jsPDF()
                pdf.addImage(url, (pdf.internal.pageSize.getWidth() / 2) - 30, (pdf.internal.pageSize.getHeight() / 2) - 30, 60, 60)
                pdf.addImage(img, (pdf.internal.pageSize.getWidth() / 2) - 5, (pdf.internal.pageSize.getHeight() / 2) - 5, 10, 10)
                pdf.save(`${name}_${surname1}-QR`);
            }
        })
    }

    render() {
        const { t, customerReducer, edit } = this.props
        const documentTypes = customerReducer.documentTypes
        const readOnly = this.props.readOnly || this.props.readEdit
        return (
            <>
                <Spinner loading={customerReducer.pending} />
                <div style={
                    {
                        padding: 50,
                        display: 'flex',
                        alignItems: 'center'
                    }
                }>
                    <h2> {this.props.title}</h2>
                    <h3 style={{marginLeft: 'auto'}}>{t('customers.details.downloadQr')}</h3>
                    <IconButton onClick={this.downloadQrCode}>
                        <Icon fontSize='large' >
                            <QrCodeIcon />
                        </Icon>
                    </IconButton>
                </div>
                <form onSubmit={this.onSubmit} autoComplete="off">
                    <Grid container spacing={3} columns={{ xs: 12, md: 12, lg: 6 }} style={{ paddingLeft: 100, paddingRight: 100 }}>
                        <Grid item lg={4} xs={12} md={12}>
                            <FormControl fullWidth>
                                <TextField
                                    label={t(
                                        'customers.form.page.personalData.name',
                                    )}
                                    value={this.state.name || ''}
                                    required
                                    variant="outlined"
                                    name="name"
                                    fullWidth
                                    onChange={this.handleChange}
                                    error={
                                        this.state.errors.name.message
                                            .length !== 0
                                    }
                                    helperText={this.state.errors.name.message}
                                    inputProps={{
                                        readOnly: readOnly,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={4} xs={12} md={12}>
                            <FormControl fullWidth>
                                <TextField
                                    label={t(
                                        'customers.form.page.personalData.surname1',
                                    )}
                                    value={this.state.surname1 || ''}
                                    required
                                    variant="outlined"
                                    name="surname1"
                                    fullWidth
                                    onChange={this.handleChange}
                                    error={
                                        this.state.errors.surname1.message
                                            .length !== 0
                                    }
                                    helperText={
                                        this.state.errors.surname1.message
                                    }
                                    inputProps={{
                                        readOnly: readOnly,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={4} xs={12} md={12}>
                            <FormControl fullWidth>
                                <TextField
                                    label={t(
                                        'customers.form.page.personalData.surname2',
                                    )}
                                    value={this.state.surname2 || ''}
                                    variant="outlined"
                                    name="surname2"
                                    fullWidth
                                    onChange={this.handleChange}
                                    error={
                                        this.state.errors.surname2.message
                                            .length !== 0
                                    }
                                    helperText={
                                        this.state.errors.surname2.message
                                    }
                                    inputProps={{
                                        readOnly: readOnly,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={4} xs={12} md={12} >
                            <Grid container spacing={3} >
                                <Grid item lg={4} sx={4} md={4} xs={3}>
                                    <FormControl fullWidth
                                    >
                                        <InputLabel id="age-label">{t('customers.form.page.personalData.document')}</InputLabel>
                                        <Select labelId="age-label"
                                            fullWidth
                                            value={this.state.documentType}
                                            onChange={this.onSelectChange}
                                            disabled={this.props.readOnly || this.props.readEdit}>
                                            {documentTypes.map(dt => (
                                                <MenuItem key={dt.id} value={dt.id}>{dt.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item lg={8} sx={8} md={8} xs={9}>
                                    <FormControl fullWidth>
                                        <TextField
                                            label={`${t(
                                                'customers.form.page.personalData.document',
                                            )} *`}
                                            value={
                                                this.state.identityDocumentCode || ''
                                            }
                                            variant="outlined"
                                            name="identityDocumentCode"
                                            fullWidth
                                            onChange={this.handleChange}
                                            error={
                                                this.state.errors.identityDocumentCode
                                                    .message.length !== 0
                                            }
                                            helperText={
                                                this.state.errors.identityDocumentCode
                                                    .message
                                            }
                                            inputProps={{
                                                readOnly: readOnly,
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={4} xs={12} md={12}>
                            <FormControl fullWidth>
                                {!this.props.isModal ? (
                                    // in modal not show tooltip because don´t exist register
                                    <TextField
                                        InputLabelProps={{
                                            style: { pointerEvents: 'auto' },
                                            shrink: true,
                                        }}
                                        label={
                                            <div>
                                                {t(
                                                    'customers.form.page.personalData.email',
                                                )}
                                                <IconWithTooltip
                                                    text={t(
                                                        'customers.form.page.personalData.tooltip.email',
                                                    )}
                                                />
                                            </div>
                                        }
                                        value={this.state.email || ''}
                                        disabled={this.state.email != '' && edit && !this.state.emailEmpty ? true : false}
                                        variant="outlined"
                                        name="email"
                                        fullWidth
                                        onChange={this.handleChange}
                                        type={'email'}
                                        error={
                                            this.state.errors.email.message
                                                .length !== 0
                                        }
                                        helperText={
                                            this.state.errors.email.message
                                        }
                                        inputProps={{
                                            readOnly: readOnly,
                                        }}
                                    />
                                ) : (
                                    <TextField
                                        label={t(
                                            'customers.form.page.personalData.email',
                                        )}
                                        value={this.state.email || ''}
                                        variant="outlined"
                                        name="email"
                                        fullWidth
                                        onChange={this.handleChange}
                                        type={'email'}
                                        error={
                                            this.state.errors.email.message
                                                .length !== 0
                                        }
                                        helperText={
                                            this.state.errors.email.message
                                        }
                                        inputProps={{
                                            readOnly: readOnly,
                                        }}
                                    />
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item lg={4} xs={12} md={12}>
                            <FormControl fullWidth error={this.state.errors.phoneNumber.message.length !== 0}>
                                <PhoneInput
                                    autoFormat={false}
                                    country={"es"}
                                    localization={es}
                                    disabled={readOnly}
                                    specialLabel={t(
                                        'customers.form.page.personalData.phone',
                                    )}
                                    placeholder={t(
                                        'customers.form.page.personalData.phone',
                                    )}
                                    inputStyle={{
                                        width: "100%", color: "rgba(0, 0, 0, 0.87)", fontSize: '0.8928571428571429rem', background: 'transparent'
                                    }}
                                    style={{
                                        color: 'rgba(0, 0, 0, 0.54)', font: 'inherit', fontSize: '0.75em',
                                        fontWeight: '400',
                                        background: 'transparent'
                                    }}
                                    value={this.state.phoneNumber}
                                    onChange={this.handleChangePhone}
                                />
                                {this.state.errors.phoneNumber.message.length !== 0 && (<FormHelperText>{this.state.errors.phoneNumber.message}</FormHelperText>)}
                            </FormControl>
                        </Grid>
                        <Grid item lg={4} xs={12} md={12}>
                            <FormControl fullWidth>
                                <GoogleMaps
                                    label={t(
                                        'customers.form.page.personalData.address',
                                    )}
                                    name="address"
                                    onChange={this.onChangeAddress}
                                    disabled={readOnly}
                                    value={this.state.address.address}
                                    error={this.state.errors.address}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={4} xs={12} md={12}>
                            <FormControl fullWidth>
                                <TextField
                                    label={t(
                                        'customers.form.page.personalData.birthDate',
                                    )}
                                    value={this.state.birthDate || ''}
                                    required
                                    variant="outlined"
                                    name="birthDate"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    type={'date'}
                                    onChange={this.handleChange}
                                    error={
                                        this.state.errors.birthDate.message
                                            .length !== 0
                                    }
                                    helperText={
                                        this.state.errors.birthDate.message
                                    }
                                    inputProps={{
                                        readOnly: readOnly,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={1} xs={12} md={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.isPMR}
                                        required
                                        onChange={() =>
                                            this.setState({
                                                isPMR: !this.state.isPMR,
                                            })
                                        }
                                        disabled={readOnly}
                                        color="primary"
                                    />
                                }
                                label={t(
                                    'customers.form.page.personalData.isPmr',
                                )}
                            />
                        </Grid>
                        <Grid item lg={1} xs={12} md={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.isDisabled}
                                        required
                                        onChange={() =>
                                            this.setState({
                                                isDisabled: !this.state.isDisabled,
                                            })
                                        }
                                        disabled={readOnly}
                                        color="primary"
                                    />
                                }
                                label={t(
                                    'customers.form.page.personalData.isDisabled',
                                )}
                            />
                        </Grid>
                        <Grid item lg={1} xs={12} md={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.hasWheelChair}
                                        required
                                        onChange={() =>
                                            this.setState({
                                                hasWheelChair:
                                                    !this.state.hasWheelChair,
                                            })
                                        }
                                        disabled={readOnly}
                                        color="primary"
                                    />
                                }
                                label={t(
                                    'customers.form.page.personalData.hasWheelChair',
                                )}
                            />
                        </Grid>
                        {
                            this.props.withAction ?
                                <Grid container spacing={3} style={{ justifyContent: 'center', marginTop: 20 }} >
                                    <Grid item lg={4} xs={6} md={6}>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            fullWidth
                                            size="large"
                                            onClick={this.props.isModal ? this.props.closeModal : this.onClickBack}
                                        >
                                            {this.props.goBack}
                                        </Button>
                                    </Grid>
                                    {allowAccessRole(MODULES.Users, OPERATIONS_ALLOWED.manage) &&
                                        <Grid item lg={4} xs={6} md={6}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                disabled={customerReducer.pending || this.checkCantEdit() ? true : false}
                                                onClick={this.onClick}
                                                size="large">
                                                {this.props.buttonRight}
                                            </Button>
                                        </Grid>}
                                </Grid>
                                : <Grid item md={8} implementation="css" smDown component={Hidden} />
                        }
                    </Grid>
                </form>
            </>
        )
    }
}

export default withTheme(PersonalData)